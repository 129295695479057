<template>
    <div class="container row mx-auto mt-10 pt-10">
        <div class="col-md-3 col-6 border text-center bg-white p-4" style="min-height: 230px; max-height: 300px;">
            <img v-lazy="imgLinks" alt="chokrojan largest bus network in Bangladesh" width="80" height="80">
            <h4 class="text-blue-light">LARGEST BUS NETWORK <br/>IN BANGLADESH</h4>
            <p>Choose from over 64 travel destinations in full country and discover Bangladesh with us.</p>
        </div>
        <div class="col-md-3 col-6 border text-center bg-white p-4" style="min-height: 230px; max-height: 300px;">
            <img v-lazy="imgComfortable" alt="chokrojan comfort on board" width="80" height="80">
            <h4 class="text-blue-light">COMFORT<br/>ON BOARD</h4>
            <p>Our buses are equipped with large and comfortable seats, toilet, wifi and power outlets.</p>
        </div>
        <div class="col-md-3 col-6 border text-center bg-white p-4" style="min-height: 230px; max-height: 300px;">
            <img v-lazy="imgOntime" alt="chokrojan bus arrive on time" width="80" height="80">
            <h4 class="text-blue-light">BUS ARRIVE<br/>ON TIME</h4>
            <p>We’ll get you there in comfort and on time: 9 out of 10 of our buses arrive punctually.</p>
        </div>
        <div class="col-md-3 col-6 border text-center bg-white p-4" style="min-height: 230px; max-height: 300px;">
            <img v-lazy="imgFriendly" alt="chokrojan travel with environmentally friendly" width="80" height="80">
            <h4 class="text-blue-light">TRAVEL WITH<br/>ENVIRONMENTALLY-FRIENDLY</h4>
            <p>Our efficient coaches are proven to have an excellent carbon footprint per driven passenger-kilometer.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BusFeatures',
    computed:{
      imgLinks: function () {
        return "media/icons/links.png";
      },
      imgComfortable: function () {
        return "media/icons/comfortable.png";
      },
      imgOntime: function () {
        return "media/icons/ontime.png";
      },
      imgFriendly: function () {
        return "media/icons/environment-friendly.png";
      }
    }
}
</script>

<style scoped>

</style>
